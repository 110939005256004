<template>
  <div>
    <div
      class="app-content content"
      style="margin-left: 1px; padding-top: 0 !important"
    >
    </div>
    <Banner
      :photo="profile.photo"
      :coverPhoto="profile.cover_photo"
      :name="profile.name"
      :showProfilePhoto="true"
      @updateProfile="openProfileEditModal"
    />
    <!-- end user profile -->

    <!-- user company list-->
    <div class="card-body mobileConpamy" style="background-color: #f0f2f5">
      <div class="col-12 col-md-10 col-lg-11 col-xl-8 m-auto">
        <div class="row">
          <div class="col-md-12">
            <div class="profileHeader pt-1 px-2">
              <div class="row">
                <div class="col-12 col-sm-8 col-lg-4">
                  <span class="text-secondary">My Companies</span>
                </div>
                <div class="col-12 col-sm-4 d-lg-none" v-if="enableCreateCompany">
                  <button
                      class="btn btn-primary w-100"
                      data-bs-toggle="modal"
                      data-keyboard="false"
                      data-backdrop="static"
                      data-bs-target="#editUser"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-plus"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                        />
                      </svg>
                      &nbsp;Add
                    </button>
                </div>
                <div class="col-12 col-lg-8">
                  <div class="row">
                    <div class="col-12 col-lg-9">
                        <div class="profileSearch">
                          <div class="input-group input-group-merge">
                            <input
                              style="font-size: 18px"
                              type="text"
                              id="fname-icon"
                              class="form-control"
                              name="fname-icon"
                              placeholder="Search company..."
                              v-model="searchCompanyString"
                            />
                          </div>
                        </div>
                    </div>
                    <div class="col-3 col-lg-3 d-none d-lg-inline-block" v-if="enableCreateCompany">
                       <button
                          class="btn btn-primary"
                          data-bs-toggle="modal"
                          data-keyboard="false"
                          data-backdrop="static"
                          data-bs-target="#editUser"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-plus"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                            />
                          </svg>
                          &nbsp;Add
                        </button>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
            <div v-if="loading" class="bg-white mt-2 p-2">
              <Loader v-for="n in 5" :key="n"/>
            </div>
            <div v-else class="row my-2">
              <div class="col-md-12 m-auto">
                <template
                  v-for="(company, index) in filterSearchCompany()"
                  :key="index"
                >
                  <CompanyCard :company="company" />
                </template>
                <br />

                <div
                  class="row new-account-button-td"
                  style="margin: 0; padding: 0"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- user company list -->

    <!-- modal for create company -->
    <div
      class="modal hide fade in"
      id="editUser"
      data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered modal-edit-user">
        <div class="modal-content" style="width: 80%; margin: 0 auto">
          <!-- <div class="modal-header bg-transparent">
          </div> -->
          <div class="modal-body px-sm-2 pt-50">
            <div class="row">
              <div class="col-10 col-sm-11 mr-auto">
                <h1>Create New Company</h1>
              </div>
              <div class="col-2 col-sm-1">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
            <hr/>
            <form
              ref="companyForm"
              id="editUserForm"
              class="row gy-1 pt-75"
              onsubmit="return false"
            >
              <!-- name -->
              <div class="col-12 col-md-12">
                <label class="form-label" for="name">Company Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="form-control"
                  placeholder="company name"
                  v-model="companiesForm.name"
                />
              </div>
              <div class="col-12 col-md-12">
                <label class="form-label" for="industry">Industry</label>
                <input
                  type="text"
                  id="industry"
                  class="form-control"
                  placeholder="industry name"
                  v-model="companiesForm.industry"
                />
              </div>
             
              <div class="col-12 col-md-12">
                <label class="form-label" for="address">Company Address</label>
                <textarea
                  id="address"
                  class="form-control"
                  placeholder="company address"
                  v-model="companiesForm.address"
                  rows="3"
                />
              </div>

              
              <div class="col-12 text-center">
                <hr class="mb-1">
                <button
                  class="btn btn-primary me-1"
                  @click="createCompanies()"
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
                <button
                  type="reset"
                  class="btn btn-outline-secondary cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal for create company -->
    <EditProfileModal
      :loading="loading"
      ref="profileModal"
      :formData="profileData"
      @onClickSubmitProfile="onClickSubmitProfile"
    />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import handleProfile from "@/services/modules/profile";
import handleCompany from "@/services/modules/company";
import { inject } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";

import CompanyCard from "@/components/molecule/profile/CompanyCard";
import EditProfileModal from "@/components/molecule/profile/EditProfileModal"
import Banner from "@/components/atom/Banner";
import Loader from "@/components/atom/loader/Loader.vue";

export default {
  name: "Profile",

  components: {
    CompanyCard,
    Banner,
    EditProfileModal,
    Loader
  },

  data() {
    return {
      searchCompanyString: "",
      companiesForm: {
        name: "",
        address: "",
        industry: "",
        area: "",
        logo: "",
        status: "",
      },
      
      profileData: {},
    };
  },

  methods: {
    ...mapMutations({
      setProfile: "user/setProfile",
      setCompany: "user/setCompany",
    }),

    async getProfile() {
      try {
        this.loading = true;
        let res = await this.fetchProfileInfo();
        if (!res.status) {
          this.showError(res.message);
        }
        if (res.status) {
          this.setProfile(res.data);
        }
      } catch (err) {
        if (!err.response) {
          this.showError("Something is wrong. Check your connectivity!!");
        }
        if (err.response) {
          this.showError(err.response.message);
        }
      } finally {
        this.loading = false;
      }
    },

    async getCompany() {
      try {
        this.loading = true;
        this.clearCompanyData()
        let res = await this.fetchCompany();
        if (!res.status) {
          // this.showError(res.message);
        }
        if (res.status) {
          this.setCompany(res.data);
        }
      } catch (err) {
        if (!err.response) {
          this.showError("Something is wrong. Check your connectivity!!");
        }
        if (err.response) {
          if(err.response.status == 401) {
            this.showError("Please log in again");
            return
          }
          this.showError(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },

    resetForm() {
      Object.keys(this.companiesForm).forEach((i) => {
        this.companiesForm[i] = "";
      });
    },

    openProfileEditModal(){
      this.profileData = Object.assign({}, this.profile)
      this.$refs.profileModal.toggleModal();
    },

    async createCompanies() {
      try {
        this.loading = true;
        this.companiesForm.status = "active";
        let res = await this.createCompany(this.companiesForm);
        if (!res.status) {
          this.showError(res.message);
        }
        if (res.status) {
          this.getCompany();
          this.resetForm();
        }
      } catch (err) {
        if (!err.response) {
          this.showError("Something is wrong. Check your connectivity!!");
        }
        if (err.response) {
          this.showError(err.response.message);
        }
      } finally {
        this.loading = false;
      }
    },
    // company search select 2 js
    filterSearchCompany() {
      return this.company.filter((item) => {
        return item.name.toLowerCase().match(this.searchCompanyString.toLowerCase());
      });
    },
    // update profile
    async onClickSubmitProfile() {
      let fd = new FormData();
      fd.append("name", this.profileData.name);
      fd.append("email", this.profileData.email);
      fd.append("phone", this.profileData.phone);
      fd.append("photo", this.profileData.photo);
      fd.append("cover_photo", this.profileData.coverPhoto);
      fd.append("_method", "put");
      try {
        this.loading = true;
        let res = await this.fetchUpdateProfile(fd);
        if (!res.status) {
          this.showError(res.message);
        }
        if (res.status) {
          this.showSuccess(res.message);
          this.getProfile();
        }
      } catch (err) {
        if (!err.response) {
          this.showError("Something is wrong. Check your connectivity!!");
        }
        if (err.response?.data) {
          this.showError(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },
  },

  setup() {
    const showError = inject("showError");
    const showSuccess = inject("showSuccess");
    const store = useStore();

    const { fetchProfileInfo, fetchCompany, loading, fetchUpdateProfile } =
      handleProfile();

    const { createCompany, clearCompanyData } = handleCompany();
    return {
      // setProfile: () => store.commit('user/setProfile'),
      // setCompany: () => store.commit('user/setCompany'),
      profile: computed(() => store.state.user.profile),
      company: computed(() => store.state.user.company),
      enableCreateCompany: computed(() =>  process.env.VUE_APP_ENABLE_COMPANY_CREATION === 'true'),
      fetchProfileInfo,
      fetchCompany,
      loading,
      showError,
      createCompany,
      showSuccess,
      fetchUpdateProfile,
      clearCompanyData,
    };
  },

  created() {
    // this.getProfile();
    this.getCompany();
  },
};
</script>


<style scoped>
#user-profile .profile-header {
  overflow: hidden;
}

#user-profile .profile-header .profile-img-container {
  position: absolute;
  bottom: -2rem;
  left: 2.14rem;
  z-index: 2;
}

#user-profile .profile-header .profile-img-container .profile-img {
  height: 8.92rem;
  width: 8.92rem;
  border: 0.357rem solid #fff;
  background-color: #fff;
  border-radius: 0.428rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

#user-profile .profile-header .profile-header-nav .navbar {
  padding: 0.8rem 1rem;
}

#user-profile .profile-header .profile-header-nav .navbar .navbar-toggler {
  line-height: 0;
}

#user-profile
  .profile-header
  .profile-header-nav
  .navbar
  .profile-tabs
  .nav-item
  i,
#user-profile
  .profile-header
  .profile-header-nav
  .navbar
  .profile-tabs
  .nav-item
  svg {
  margin-right: 0;
}

#user-profile #profile-info .profile-star {
  color: #babfc7;
}

#user-profile #profile-info .profile-star i.profile-favorite,
#user-profile #profile-info .profile-star svg.profile-favorite {
  fill: #ff9f43;
  stroke: #ff9f43;
}

#user-profile #profile-info .profile-likes {
  fill: #ea5455;
  stroke: #ea5455;
}

#user-profile #profile-info .profile-polls-info .progress {
  height: 0.42rem;
}

#user-profile .profile-latest-img {
  transition: all 0.2s ease-in-out;
}

#user-profile .profile-latest-img:hover {
  transform: translateY(-4px) scale(1.2);
  z-index: 10;
}

#user-profile .profile-latest-img img {
  margin-top: 1.28rem;
}

#user-profile .block-element .spinner-border {
  border-width: 0.14rem;
}

@media (max-width: 991.98px) {
  #user-profile .profile-latest-img img {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .profile-header-nav .profile-tabs {
    width: 100%;
    margin-left: 13.2rem;
  }
}

@media (max-width: 575.98px) {
  #user-profile .profile-header .profile-img-container .profile-img {
    height: 100px;
    width: 100px;
  }
  #user-profile .profile-header .profile-img-container .profile-title h2 {
    font-size: 1.5rem;
  }
}

.header-navbar.fixed-top,
.header-navbar.floating-nav {
  left: 1px !important;
}

.headingTwo {
  background-color: #666666;
  padding: 10px 0;
  color: #fff;
}
.vertical-container {
  position: relative;
}
.new-account-button {
  width: 40%;
  padding: 5px 30px;
  /* color: #136acd; */
  margin: 0 auto;
  border: none;
  background: transparent;
  font-size: 18px;
  background-color: #7367f0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.5s ease;
  border-radius: 20px;
}
.new-account-button:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.cardR {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.cardR:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.profileHeader {
  /* background-color: #d1cef1; */
  background-color: #fff;
  margin: 0;
  padding:15px 0;
  color: #000;
  font-size: 28px;
  border-radius: 15px;
}
.profileHeader .headerName {
  margin-top: 7px;
  /* width: 30%; */
}
.profileHeader .profileSearch {
  /* width: 50%; */
  text-align: right !important;
}

.profileHeader .profileSearch .form-control {
  background-color: #f0f2f5;
  border-radius: 30px;
}
.profileHeader h2 {
  color: #1f283f;
  text-align: left;
}

.addButtonAbove {
  display: none;
}
.cancel-btn{
  background-color: #F0F2F5; color: #7D7D7D;
}
.cancel-btn:hover{
  background-color: #e9edf5;
}
hr{
  margin: 0 -3%;
  width: 106%;
}
/* hr{
  margin-top: 10px;
} */
.btn-close {
    /* margin-left: 200px; */
    /* font-size: 32px; */
    /* margin-top: 5px; */
    width: 30px;
    height: 30px;
    background-color: #E4E6EB;
    border-radius: 50%;
  }
  .btn-close:hover {
    width: 30px;
    height: 30px;
    background-color: #D8DADF;
    border-radius: 50%;
  }
@media screen and (min-width: 1200px) {
  .btn-close {
    /* margin-left: 200px; */
    /* font-size: 32px; */
    /* margin-top: 5px; */
    width: 30px;
    height: 30px;
    background-color: #E4E6EB;
    border-radius: 50%;
    /* margin-right: 30px; */
  }
  .btn-close:hover {
    width: 30px;
    height: 30px;
    background-color: #D8DADF;
    border-radius: 50%;
  }
}

@media screen and (max-width: 991px) {
  .mobileCompany {
    font-size: 16px !important;
    text-align: center !important;
  }
  .mobileCompany .imgDiv {
    /* padding-top: 5px; */
    padding-top: 5px;
  }
  .mobileCompanyButton {
    font-size: 16px !important;
    width: 100%;
  }
  .profileHeader h2 {
    width: 60%;
    text-align: left !important;
    display: inline-block;
  }
  .profileHeader .headerName {
    margin-top: 7px;
    /* width: 100%; */
    /* text-align: center; */
    padding: 10px;
  }
  .profileHeader .profileSearch {
    width: 100% !important;
    /* padding: 15px 10px; */
    margin-top: 10px;
  }
  .profileHeader .addButton {
    width: 100% !important;
    padding: 10px;
    display: none;
    /* text-align: left; */
  }
  .addButtonAbove {
    display: inline-block;
    display: inline;
    width: 40%;
    /* text-align: right; */
  }
  /* .modal-body h1{
    font-size: 18px;
  } */
  hr{
  margin: 0 -8%;
  width: 116%;
}
.btn-close {
    width: 25px;
    height: 25px;
    background-color: #E4E6EB;
    border-radius: 50%;
    margin-top: -5px;
    margin-right: -5px;
  }
}

</style>